<template>
  <div class="about">
    
  </div>
</template>
<script>

export default {
    name: 'auth',
    mounted(){
        this.seturl();
    },
    methods: {
      seturl(){
        this.$router.push({name: 'Personal'})
       
      }
    }
}

</script>